<template>
  <div style="position: relative;" class="report-v2"  v-if="field.Visable">
    <van-field :required="field.Nullable == true" :disabled="isEditSaved || !field.Editable"  :label-width="config.labelWidth"
               :placeholder="config.description"  :rows="config.rows" :type="config.type" readonly
               :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >
      <template slot="label">
        <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + config.wordColor +'; font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px; height: ' +
            config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'">{{ config.name }}</div>
      </template>
      <template slot="input">
        <div
                v-if="dataSet[tablecode][rowindex][config.FieldCode]==null || dataSet[tablecode][rowindex][config.FieldCode]==''">
          <input class="inputIcon" type="file" accept="*"  @click="editFieldBefore(tablecode, rowindex, config.FieldCode)" @change="onClickUploadAffix($event, 0)"  :disabled="isEditSaved || !field.Editable"
                 :style="'font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px; background-color: #fff; height: ' +
          config.cellHeight + 'px; line-height: ' +  config.cellHeight
              + 'px;  '" />
        </div>
        <div
                v-if="dataSet[tablecode][rowindex][config.FieldCode]!=null && dataSet[tablecode][rowindex][config.FieldCode]!=''"
                class="img-box-main">
          <div class="img-line-main"
               :style="'font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px; background-color: #fff; height: ' +
          config.cellHeight + 'px; line-height: ' +  config.cellHeight
              + 'px;  '" >
            <a class="detail-affix"
               @click="previewAffix(resourceHome + popAffixInfo(dataSet[tablecode][rowindex][config.FieldCode+'_id']).Url)">
              {{dataSet[tablecode][rowindex][config.FieldCode]}}
            </a>
            <van-icon name="delete-o" class="delete-affix-icon"
                      @click="removeAffix(tablecode,config.FieldCode,rowindex)" v-if="!isEditSaved && field.Editable"/>
          </div>
        </div>
      </template>

    </van-field>
  </div>
</template>

<script>
import { Uploader, Loading, Field, Icon } from 'vant'
export default {
    name: 'nxMobileFile',
    components: {
        [Uploader.name]: Uploader,
        [Field.name]: Field,
        [Loading.name]: Loading,
        [Icon.name]: Icon

    },
    props: {
        dataSet: Object,
        value: String,
        config: Object,
        children: Array,
        mastertable: Object,
        tables: Array,
        cssdiy: Object,
        label: String,
        isEditSaved: Boolean,
        onClickUploadImage: Function,
        popImageInfo: Function,
        onClickShowImage: Function,
        removeImage: Function,
        removeAffix: Function,
        resourceHome: String,
        rowindex: Number,
        tablecode: String,
        editFieldBefore: Function,
        popAffixInfo: Function,
        previewAffix: Function,
        onClickUploadAffix: Function
    },
    data () {
        return {
            field: null
        }
    },
    mounted () {
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            if (table.TableCode === this.tablecode) {
                for (let j = 0; j < table.Fields.length; j++) {
                    if (table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
            }
            if (this.field !== null) {
                break
            }
        }
    },
    methods: {
        editFieldBefor () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
        }
    }
}
</script>

<style scoped>

</style>
