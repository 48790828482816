import axios from '../utils/http'

export default {
    TestHeart: (data) => {
        return axios.post('/open/TestHeart', data).catch(err => err)
    },
    login: (data) => {
        return axios.post('/Authorize/GetAccessTokenV2', data).catch(err => err)
    },
    getDeputeUsers: (data) => {
        return axios.post('/Authorize/GetDeputeUsers', data).catch(err => err)
    },

    sendVerifyCode: (data) => {
        return axios.post('/Authorize/SendVerifyCode', data).catch(err => err)
    },
    getUserInfo: (data) => {
        return axios.post('/Authorize/GetUserInfo', data).catch(err => err)
    },
    getAccountCheckMustVerifyCode: (data) => {
        return axios.post('/Authorize/GetAccountCheckMustVerifyCode', data).catch(err => err)
    },
    get: (url, data) => {
        return axios.get(url, { params: data }).catch(err => err)
    },
    getAnonymousToken: (data) => {
        return axios.post('/open/getAnonymousToken', data).catch(err => err)
    },
    checkAccessTokenOpen: (data) => {
        return axios.post('/open/checkAccessToken', data).catch(err => err)
    },
    //获取登录令牌
    getAccessToken: (data) => {
        return axios.post('/Authorize/getAccessToken', data).catch(err => err)
    },
    //匿名访问
    getAnonymousAccessToken: (data) => {
        return axios.post('/Authorize/getAnonymousAccessToken', data).catch(err => err)
    },
    uploadMyPhoto: (data)  =>  {
        return axios.post('/Authorize/uploadMyPhoto', data).catch(err => err)
    },
    getWebChatAppId: (data) => {
        return axios.post('/Authorize/getWebChatAppId', data).catch(err => err)
    },
    getQYWXAppId: (data) => {
        return axios.post('/Authorize/getQYWXAppId', data).catch(err => err)
    },
    getDingCorpId: (data) => {
        return axios.post('/Authorize/GetDingCorpId', data).catch(err => err)
    },
    //微信授权绑定后获取登陆令牌
    getAccessTokenAndBindWeChat: (data) => {
        return axios.post('/Authorize/getAccessTokenAndBindWeChat', data).catch(err => err)
    },
    //钉钉授权绑定后获取登陆令牌
    getAccessTokenAndBindDing: (data) => {
        return axios.post('/Authorize/getAccessTokenAndBindDing', data).catch(err => err)
    },
    //微信授权绑定后获取登陆令牌
    getAccessTokenAndBindQYWeChat: (data) => {
        return axios.post('/Authorize/getAccessTokenAndBindQYWeChat', data).catch(err => err)
    },
    //微信注册后获取登录令牌
    registerFromWeChat: (data) => {
        return axios.post('/Authorize/registerFromWeChat', data).catch(err => err)
    },
    //释放令牌退出登
    removeAccessToken: (data) => {
        return axios.post('/Authorize/removeAccessToken', data).catch(err => err)
    },
    getWXOAuthToken: (data) => {
        return axios.post('/Authorize/GetWebChatOAuthAccessToken', data).catch(err => err)
    },
    getDingOAuthToken: (data) => {
        return axios.post('/Authorize/GetDingDingOAuthAccessToken', data).catch(err => err)
    },
    getQYWXOAuthToken: (data) => {
        return axios.post('/Authorize/GetQYWebChatOAuthAccessToken', data).catch(err => err)
    },
    getQYWXUserID: (data) => {
        return axios.post('/Authorize/GetQYWebChatUserID', data).catch(err => err)
    },
    getDingUserID: (data) => {
        return axios.post('/Authorize/GetDingUserID', data).catch(err => err)
    },

    //微信免登录
    getAccessTokenFromWeChat: (data) => {
        return axios.post('/Authorize/getAccessTokenFromWeChat', data).catch(err => err)
    },
    //企业微信免登录
    getQYAccessTokenFromWeChat: (data) => {
        return axios.post('/Authorize/GetQYWeAccessTokenFromWeChat', data).catch(err => err)
    },
    //钉钉免登录
    getAccessTokenFromDing: (data) => {
        return axios.post('/Authorize/GetAccessTokenFromDing', data).catch(err => err)
    },

    getWeChatJsApiTicket: (data) => {
        return axios.post('/Authorize/getWeChatJsApiTicket', data).catch(err => err)
    },
    getQYWXJsApiTicket: (data) => {
        return axios.post('/Authorize/getQYWXJsApiTicket', data).catch(err => err)
    },
    getAccount: (data) => {
        return axios.get('/Authorize/GetAccountV2', { params: data }).catch(err => err)
    },
    getBaiduMapAk: (data) => {
        return axios.get('/Authorize/GetBaiduMapAk', { params: data }).catch(err => err)
    },
    getXfYunKey: (data) => {
        return axios.get('/Authorize/GetXfYunKey', { params: data }).catch(err => err)
    },
    checkWebAppDisable: (data) => {
        return axios.get('/authorize/CheckWebAppDisable', { params: data }).catch(err => err)
    },
    //获取是否允许注册
    getAccountRegister: (data) => {
        return axios.post('/Authorize/GetAccountRegisterEnable', data).catch(err => err)
    },
    getHomeMessage: (data) => {
      return axios.get('/', { params: data }).catch(err => err)
    },
    getHomeTasks: (data) => {
      return axios.get('/', { params: data }).catch(err => err)
    },
    checkAccessToken: (data) => {
        return axios.post('/Authorize/CheckAccessTokenV2', data).catch(err => err)
    },
    getAllowCaptcha: (data) => {
        return axios.post('/Authorize/GetAllowCaptcha', data).catch(err => err)
    },
    getImageCapatcha: (data) => {
        return axios.post('/Authorize/GetImageCapatcha', data).catch(err => err)
    },
    getMenuList: (data) => {
        return axios.get('/report/GetMenuList', { params: data }).catch(err => err)
    },
    // 获取BadgeCount
    getBadgeCount: (data) => {
        return axios.post('/report/GetBadgeCount', data).catch(err => err)
    },
    getMenuChartList: (data) => {
        return axios.get('/report/GetMenuChartList', { params: data }).catch(err => err)
    },
    getHuiChartMobileList: (data) => {
        return axios.post('/report/GetHuiChartMobileList', data).catch(err => err)
    },
    uploadMobileCommonTemplate: (data) => {
        return axios.post('/report/UploadMobileCommonTemplate', data).catch(err => err)
    },
    // 获取任务列表
    getTaskList: (data) => {
        return axios.post('/workflow/GetTaskList', data).catch(err => err)
    },
    // 获取任务菜单
    getTaskTree: (data) => {
        return axios.post('/workflow/GetTaskTree', data).catch(err => err)
    },
    getAllTask: (data) => {
        return axios.post('/workflow/GetAllTask', data).catch(err => err)
    },

    getReportListSchema: (data) => {
        return axios.post('/report/getReportListSchema', data).catch(err => err)
    },
    getReportListData: (data) => {
        return axios.post('/report/getReportListData', data).catch(err => err)
    },
    // 获取界面数据结构
    getReportSchema: (data) => {
        return axios.post('/report/getReportSchema', data).catch(err => err)
    },
    // 获取任务id
    getTaskIDByRecordID: (data) => {
        return axios.post('/report/GetTaskIDByRecordID', data).catch(err => err)
    },
    // 获取工作台自定义按钮
    getReportConsoleBtn: (data) => {
        return axios.post('/report/getReportConsoleBtn', data).catch(err => err)
    },
    //修改密码
    changeMyPassword: (data) => {
        return axios.post('/report/changeMyPassword', data).catch(err => err)
    },
    // 工作台自定义按钮执行
    execConsoleCommand: (data) => {
        return axios.post('/report/execConsoleCommand', data).catch(err => err)
    },
    //修改报表前检查
    toEditReport: (data) => {
        return axios.post('/report/toEditReport', data).catch(err => err)
    },
    // 获取设计数据信息
    getReportFormSchemV2: (data) => {
        return axios.post('/report/GetReportFormSchemV2', data).catch(err => err)
    },
    // 保存设计数据信息
    saveReportFormSchemV2: (data) => {
        return axios.post('/report/SaveReportFormSchemV2', data).catch(err => err)
    },
    //获取查询数据
    getQueryData: (data) => {
        return axios.post('/report/getQueryData', data).catch(err => err)
    },
    //获取报表数据
    getOpenReportData: (data) => {
        return axios.post('/report/getOpenReportData', data).catch(err => err)
    },
    //获取支付订单id
    toWeChatPay: (data) => {
        return axios.post('/report/toWeChatPay', data).catch(err => err)
    },
    //支付
    goWeChatPay: (data) => {
        return axios.post('/report/goWeChatPay', data).catch(err => err)
    },
    //H5支付
    goWeChatPayH5: (data) => {
        return axios.post('/report/goWeChatPayH5', data).catch(err => err)
    },
    getWeChatPayStatus: (data) => {
        return axios.post('/report/getWeChatPayStatus', data).catch(err => err)
    },
    //新增报表数据
    addReport: (data) => {
        return axios.post('/report/addReport', data).catch(err => err)
    },
    //新增报表数据
    getQueryInfo: (data) => {
        return axios.post('/report/GetQueryInfo', data).catch(err => err)
    },
    //删除图片附件
    removeResource: (data) => {
       return axios.post('/report/removeResource', data).catch(err => err)
    },
    //计算excel公式
    calcExcelFormula: (data) => {
        return  axios.post('/report/calcExcelFormula', data).catch(err => err)
    },
    //执行控制规则
    execCtrlRule: function (data) {
        return  axios.post('/report/execCtrlRule', data).catch(err => err)
    },
    //执行报表命令
    execReportAction: function (data) {
        return  axios.post('/report/execReportAction', data).catch(err => err)
    },
    //执行报表命令
    execReportActionV2: function (data) {
        return  axios.post('/report/execReportActionV2', data).catch(err => err)
    },
    // 执行公式
    execFormula: (data) => {
        return axios.post('/report/execFormula', data).catch(err => err)
    },
    //删除报表
    toDeleteReport: (data) => {
        return axios.post('/report/toDeleteReport', data).catch(err => err)
    },
    //删除报表
    deleteReport: (data) => {
        return axios.post('/report/deleteReport', data).catch(err => err)
    },
    //执行关联前判断
    execLinker: (data) => {
        return axios.post('/report/execLinker', data).catch(err => err)
    },
    //获取关联报表id
    getLinkerRecordID: (data) => {
        return axios.post('/report/getLinkerRecordID', data).catch(err => err)
    },
    // 工作流任务信息
    getTaskInfo: (data) => {
        return axios.post('/workflow/getTaskInfo', data).catch(err => err)
    },
    //工作流任务办理检查
    toDealTask: (data) => {
        return axios.post('/workflow/toDealTask', data).catch(err => err)
    },
    //工作流任务取消办理（解锁）
    toDealTaskCancel: (data) => {
        return axios.post('/workflow/toDealTaskCancel', data).catch(err => err)
    },
    //工作流任务取消办理（解锁）
    cancelDoingTask: (data) => {
        return axios.post('/workflow/cancelDoingTask', data).catch(err => err)
    },
    //撤销工作流
    cancelTask: (data) => {
        return axios.post('/workflow/cancelTask', data).catch(err => err)
    },
    //工作流任务提交
    commitTask: (data) => {
        return axios.post('/workflow/commitTask', data).catch(err => err)
    },
    //工作流任务提交继续
    commitTaskContinue: (data) => {
        return axios.post('/workflow/commitTaskContinue', data).catch(err => err)
    },
    //工作流任务回退
    backTask: (data) => {
        return axios.post('/workflow/backTask', data).catch(err => err)
    },
    //工作流任务回退继续
    backTaskContinue: (data) => {
        return axios.post('/workflow/backTaskContinue', data).catch(err => err)
    },

    //工作流任务转办
    replaceTask: (data) => {
        return axios.post('/workflow/replaceTask', data).catch(err => err)
    },
    //工作流进度详情
    getTaskRouteList: (data) => {
        return axios.post('/workflow/getTaskRouteList', data).catch(err => err)
    },
    //最后一个工作流
    getLastTaskIDByRecordID: (data) => {
        return axios.post('/workflow/GetLastTaskIDByRecordID', data).catch(err => err)
    },
    //选择流程任务接收人
    getReceivers: (data) => {
        return axios.post('/workflow/getReceivers', data).catch(err => err)
    },
  // 获取消息列表
  getNoticeList: (data) => {
    return axios.post('/notice/GetNoticeList', data).catch(err => err)
  },
    //获取工作流任务列表数量
    getTaskListCount: (data) => {
        return axios.post('/workflow/getTaskListCount', data).catch(err => err)
    },
    //获取消息列表数量
    getNoticeListCount: (data) => {
        return axios.post('/notice/getNoticeListCount', data).catch(err => err)
    },
    //获取预警列表数量
    getWarningListCount: (data) => {
        return axios.post('/notice/getWarningListCount', data).catch(err => err)
    },
    //获取预警列表
    getWarningList: (data) => {
        return axios.post('/notice/getWarningList', data).catch(err => err)
    },
    // 设置已阅
    setNoticeRead: (data) => {
      return axios.post('/Notice/SetNoticeRead', data).catch(err => err)
    },
    // 获取消息关联内容
    getNoticeRefInfo: (data) => {
      return axios.post('/Notice/GetNoticeRefInfo', data).catch(err => err)
    },
    // 获取工作台表结构
    getReportSchem: (data) => {
      return axios.post('/App/GetReportSchem', data).catch(err => err)
    },
    // 获取工作台主表数据
    getReportDataList: (data) => {
      return axios.post('/App/GetReportDataList', data).catch(err => err)
    },
    // 获取外部界面信息
    getCustomUi: (data) => {
      return axios.post('/App/GetCustomUi', data).catch(err => err)
    },
    // 获取工作台明细表数据
    getReportMultDataList: (data) => {
      return axios.post('/App/getReportMultDataList', data).catch(err => err)
    },
    getAppMenuTree: (data) => {
      return axios.get('/App/GetAppMenuTree', { params: data }).catch(err => err)
    },
    // 获取自定义界面数据
    getReportFormData: (data) => {
      return axios.post('/FormReport/GetReportFormData', data).catch(err => err)
    },
    // 获取下拉数据
    getCombItems: (data) => {
        return axios.post('/DataSpec/getCombItems', data).catch(err => err)
    },
    // 获取下拉数据
    getCombItemsV2: (data) => {
        return axios.post('/DataSpec/getCombItemsV2', data).catch(err => err)
    },
    // 获取复选框数据
    getCheckboxItemList: (data) => {
        return axios.post('/DataSpec/getCheckboxItemList', data).catch(err => err)
    },
    //获取数据规范的数据结构
    getDataSpecSchema: (data) => {
        return axios.post('/DataSpec/getDataSpecSchema', data).catch(err => err)
    },
    //获取数据规范的数据记录
    getDataSpecRecord: (data) => {
        return axios.post('/DataSpec/getDataSpecRecord', data).catch(err => err)
    },
    //获取数据规范id
    getDataSpecID: (data) => {
        return axios.post('/DataSpec/GetDataSpecID', data).catch(err => err)
    },
    //获取复选框
    getAllCheckboxItems: (data) => {
        return axios.post('/DataSpec/getAllCheckboxItems', data).catch(err => err)
    },
    // 获取列表个数
    getDataSpecInfoList: (data) => {
      return axios.post('/BDataSpec/GetDataSpecInfoList', data).catch(err => err)
    },
    // 暂时不用
    getDataSpecColumns: (data) => {
      return axios.post('/BDataSpec/GetDataSpecColumns', data).catch(err => err)
    },
    // 获取复选框数据
    getNxCheckboxInfo: (data) => {
      return axios.post('/BDataSpec/GetNxCheckboxInfo', data).catch(err => err)
    },
    // 获取树形数据
    getDataSpecTree: (data) => {
      return axios.post('/BDataSpec/GetDataSpecTree', data).catch(err => err)
    },
    // 获取一级分类
    getDataSpecListInitTree: (data) => {
      return axios.post('/BDataSpec/GetDataSpecListInitTree', data).catch(err => err)
    },
    // 获取子级分类
    getDataSpecListSubTree: (data) => {
      return axios.post('/BDataSpec/GetDataSpecListSubTree', data).catch(err => err)
    },
    // 获取列表数据
    getDataSpecList: (data) => {
      return axios.post('/BDataSpec/getDataSpecList', data).catch(err => err)
    },
    // 获取公式列表
    getFormulaListData: (data) => {
      return axios.post('/Formula/GetFormulaListData', data).catch(err => err)
    },
    // 执行公式
    execFormulaReport: (data) => {
      return axios.post('/FormReport/ExecFormula', data).catch(err => err)
    },
    // 执行规范
    execCtrlExpression: (data) => {
      return axios.post('/FormReport/ExecCtrlExpression', data).catch(err => err)
    },
    // 获取模板信息
    getTemplateSsjson: (data) => {
      return axios.post('/Template/GetTemplateSsjson', data).catch(err => err)
    },
    // 获取模板所以报表命令
    getNxActionSummaryList: (data) => {
      return axios.post('/FormReport/GetNxActionSummaryList', data).catch(err => err)
    },
    // 获取所以报表命令
    getNxCommandList: (data) => {
      return axios.post('/FormReport/GetNxCommandList', data).catch(err => err)
    },
    // 获取用户列表
    getUserTree: (data) => {
        return axios.post('/FormReport/GetUserTree', data).catch(err => err)
    },
    // 加密
    getEncrypt: (data) => {
      return axios.post('/FormReport/GetEncrypt', data).catch(err => err)
    },
    // AES加密
    getAESEncrypt: (data) => {
      return axios.post('/FormReport/GetAESEncrypt', data).catch(err => err)
    },
    // 上传图片
    UploadImage: (data) => {
      return axios.post('/report/UploadImage', data).catch(err => err)
    },
    GetFileServerResource: (data) => {
        return axios.post('/report/GetFileServerResource', data).catch(err => err)
    },
    OpenRefDoc: (data) => {
        return axios.post('/report/OpenRefDoc', data).catch(err => err)
    },
    // 上传附件
    UploadResource: (data) => {
        return axios.post('/report/UploadResource', data).catch(err => err)
    },// 上传附件
    getImageOrAffixUrl: (data) => {
        return axios.post('/report/GetImageOrAffixUrl', data).catch(err => err)
    },
    // 删除图片或附件
    RemoveResource: (data) => {
      return axios.post('/FormReport/RemoveResource', data).catch(err => err)
    },
    // 获取模板中所有字段
    getTemplateFields: (data) => {
      return axios.post('/Template/GetTemplateFields', data).catch(err => err)
    },
    // 获取模板控制信息（工作台）
    getTemplateShowControl: (data) => {
      return axios.post('/Template/GetTemplateShowControl', data).catch(err => err)
    },
    // 获取模板工作台条件格式
    getConditionalRule: (data) => {
      return axios.post('/Template/GetConditionalRule', data).catch(err => err)
    },
    // 获取模板信息
    getTemplateInfo: (data) => {
      return axios.post('/Template/GetTemplateInfo', data).catch(err => err)
    },
    // 获取无焦点打开模板
    getAllTemplateScanner: (data) => {
      return axios.post('/Template/GetAllTemplateScanner', data).catch(err => err)
    },
    // 执行公式结果
    getExecFormulaResult: (data) => {
      return axios.post('/Formula/GetExecFormulaResult', data).catch(err => err)
    },
    // 执行回写公式
    execWBFormula: (data) => {
      return axios.post('/Formula/execWBFormula', data).catch(err => err)
    },
    // 执行新建公式
    execNRFromulaManual: (data) => {
      return axios.post('/Formula/execNRFromulaManual', data).catch(err => err)
    },
    // 执行存储过程
    executeSqlProcedure: (data) => {
      return axios.post('/NxAction/executeSqlProcedure', data).catch(err => err)
    },
    // 保存报表
    saveReport: (data) => {
      return axios.post('/Report/SaveReport', data).catch(err => err)
    },
    // 获取显示多少按钮
    getReportToolbars: (data) => {
      return axios.post('/BReport/GetToolbars', data).catch(err => err)
    },
    // 锁定或解锁报表
    updateReportLockState: (data) => {
      return axios.post('/BReport/UpdateReportLockState', data).catch(err => err)
    },
    // 审核
    doCheck: (data) => {
      return axios.post('/BReport/DoCheck', data).catch(err => err)
    },
    // 反审核
    doUnCheck: (data) => {
      return axios.post('/BReport/DoUnCheck', data).catch(err => err)
    },
    // 转pdf
    reportToPDF: (data) => {
      return axios.post('/BReport/ReportToPDF', data).catch(err => err)
    },
    // 转Excel
    reportToExcel: (data) => {
      return axios.post('/FormReport/ReportToExcel', data).catch(err => err)
    },
    // 获取打印机列表
    getServicePrinters: (data) => {
      return axios.post('/FormReport/GetServicePrinters', data).catch(err => err)
    },
    //  获取打印机信息
    getPrinterSetting: (data) => {
      return axios.post('/BReport/GetPrinterSetting', data).catch(err => err)
    },
    // 发送后台打印命令打印
    reportToPrint: (data) => {
      return axios.post('/BReport/ReportToPrint', data).catch(err => err)
    },
    // 获取附件
    getAffixInfo: (data) => {
      return axios.post('/BReport/GetAffixInfo', data).catch(err => err)
    },
    // 作废
    doVoided: (data) => {
      return axios.post('/BReport/DoVoided', data).catch(err => err)
    },
    // 反作废
    doUnVoided: (data) => {
      return axios.post('/BReport/DoUnVoided', data).catch(err => err)
    },
    // 归档
    doArchive: (data) => {
      return axios.post('/BReport/DoArchive', data).catch(err => err)
    },
    // 编辑前检查
    editeRportBeforeCheck: (data) => {
      return axios.post('/BReport/EditeRportBeforeCheck', data).catch(err => err)
    },
    // 获取关联表的rcid
    getLinkerReportID: (data) => {
      return axios.post('/BReport/GetLinkerReportID', data).catch(err => err)
    },
    // 直接提交任务
    WFSubmitWorkbook: (data) => {
      return axios.post('/BWorkflow/WFSubmitWorkbook', data).catch(err => err)
    },
    // 直接回退任务
    WFBackWorkbook: (data) => {
      return axios.post('/BWorkflow/WFBackWorkbook', data).catch(err => err)
    },
    // 选择后提交任务
    WFSubmitReportManual: (data) => {
      return axios.post('/BWorkflow/WFSubmitReportManual', data).catch(err => err)
    },
    // 办理任务
    DealWorkflow: (data) => {
      return axios.post('/BWorkflow/DealWorkflow', data).catch(err => err)
    },
    // 取消办理任务（主要解锁）
   CancelDealWorkflow: (data) => {
    return axios.post('/BWorkflow/CancelDealWorkflow', data).catch(err => err)
    },
    // 办理任务(抢办)
    DealWorkflowGoto: (data) => {
      return axios.post('/BWorkflow/DealWorkflowGoto', data).catch(err => err)
    },
    // 查看任务
    getVIDByWIID: (data) => {
      return axios.post('/BWorkflow/GetVIDByWIID', data).catch(err => err)
    },
  // 撤销任务
  UndoWorkflow: (data) => {
    return axios.post('/BWorkflow/UndoWorkflow', data).catch(err => err)
  },
  // 撤销任务(继续)
  UndoWorkflowGoto: (data) => {
    return axios.post('/BWorkflow/UndoWorkflowGoto', data).catch(err => err)
  },
  // 获取任务信息
  getWorkflowTask: (data) => {
    return axios.post('/BWorkflow/GetWorkflowTask', data).catch(err => err)
  },
  deleteReportBeforeCheck: (data) => {
    return axios.post('/BReport/DeleteReportBeforeCheck', data).catch(err => err)
  },
  getXLSXFile: (url, params) => {
    // 这里不能用 axios.get 封装，否则返回的数据流会出现乱码，不是 arraybuffer
    return axios.request({
      url: url,
      method: 'get',
      params: params,
      responseType: 'arraybuffer',
      responseEncoding: 'utf8'
    })
  }
}
