<template>
  <div style="position: relative;">
    <div
            v-if="!isList && (dataSet[tablecode][rowindex][config.FieldCode]==null || dataSet[tablecode][rowindex][config.FieldCode]=='')">
      <van-uploader @click-upload="editFieldBefor" :after-read="onClickUploadImage" :disabled="isEditSaved || !field.Editable"/>
    </div>
    <div
            v-if="!isList && (dataSet[tablecode][rowindex][config.FieldCode]!=null && dataSet[tablecode][rowindex][config.FieldCode]!='')"
            class="img-box-main">
      <div class="img-line-main">
        <van-image
                :fit="config.fit ? config.fit : 'contain'"
                :src="resourceHome + popImageInfo(dataSet[tablecode][rowindex][config.FieldCode+'_id']).ThumbUrl"
                alt=""
                @click="onClickShowImage(0,dataSet[tablecode][rowindex][config.FieldCode+'_id'],0)"
                class="detail-img" width="100%" :height="config.cellHeight" />
        <van-icon name="delete-o" class="delete-icon" style="position: relative;
    top: -20px;
    right: -80%"
                  @click="removeImage(tablecode,config.FieldCode,rowindex)" v-if="!isEditSaved && field.Editable"/>
      </div>
    </div>
    <div v-if="isList"  class="img-box-main">
      <div class="img-line-main">
        <!--{{dataRow}}-->
        <van-image
                :id="'outList'"
                :height="config.cellHeight"
                :fit="config.fit ? config.fit : 'contain'"
                :src="resourceHome + thumbUrl"
                alt=""
                class="detail-img" width="100%"/>
      </div>
    </div>
  </div>
</template>

<script>
import { Uploader, Loading, Image, Icon } from 'vant'
export default {
    name: 'nxMobilePicture',
    components: {
        [Image.name]: Image,
        [Uploader.name]: Uploader,
        [Image.name]: Image,
        [Uploader.name]: Uploader,
        [Loading.name]: Loading,
        [Icon.name]: Icon

    },
    props: {
        mastertable: Object,
        tables: Array,
        dataSet: Object,
        value: String,
        config: Object,
        children: Array,
        cssdiy: Object,
        label: String,
        isEditSaved: Boolean,
        onClickUploadImage: Function,
        popImageInfo: Function,
        onClickShowImage: Function,
        removeImage: Function,
        resourceHome: String,
        rowindex: Number,
        tablecode: String,
        onClickUploadAffix: Function,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        dataRow: Object,
        isList: {
            type: Boolean,
            default: false
        },
        refresh: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            field: null,
            thumbUrl: ''
        }
    },
    watch: {
        async refresh () {
            if (this.isList) {
                this.thumbUrl = await this.getImageOrAffixUrl(this.dataRow['app'][this.config.FieldCode])
            }
        }
    },
    async mounted () {
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            for (let j = 0; j < table.Fields.length; j++) {
                if (table.Fields[j].FieldCode === this.config.FieldCode) {
                    this.field = table.Fields[j]
                    break
                }
            }
            if (this.field !== null) {
                break
            }
        }
        if (this.isList) {
            this.thumbUrl = await this.getImageOrAffixUrl(this.dataRow['app'][this.config.FieldCode])
        }
    },
    methods: {
        editFieldBefor () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.editFieldBeforeV2()
        },
        async getImageOrAffixUrl (id) {
            const result = await this.$api.getImageOrAffixUrl({ NxStr: id })
            console.log(result)
            if (result.Code === 0) {
                return result.imageinfo.ThumbUrl
            } else {
                return ''
            }
        }
    }
}
</script>

<style scoped>
    .nxicon {

    }

</style>
