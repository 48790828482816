<template>
  <div style="position: relative;"  v-if="field.Visable">
    <van-field :required="field.Nullable == true" :right-icon="(isEditSaved || !field.Editable) ? '' : 'cross'"
               @click-right-icon="changeValue(tablecode, rowindex, config.FieldCode, '')"
               :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >
      <template slot="label">
        <div v-if="config.labelWidth !== '0'"
             :style="'width: ' + config.labelWidth + 'px; color: ' + config.wordColor +'; font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px; height: ' +
             (config.cellHeight - 20) + 'px; line-height: ' + (config.cellHeight - 20) + 'px;'">{{ config.name }}</div>
      </template>
      <template slot="input">
        <input :placeholder="'选择日期'" name="calendar"
               readonly :center="true"
               :disabled="isEditSaved || !field.Editable"
               @click="editFieldBefor"
               v-model="dataSet[tablecode][rowindex][config.FieldCode]"
               :style="'font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px; background-color: #fff; height: ' +
             (config.cellHeight - 20) + 'px; line-height: ' + (config.cellHeight - 20) + 'px;'"  />
      </template>
      <template #button>
        <van-button v-if="field.Linkers != null  && field.Linkers.length > 0"  type="default" style="width: 12px;height: 12px;" :icon="require('@/assets/images/report/linker.png')" @click="linkReport()"></van-button>
      </template>
    </van-field>
    <van-calendar  v-if="!config.hasTime"
                   :default-date="defaultDate"
                   :min-date="minDate"
                   :max-date="maxDate"
                   v-model="showPicker" @confirm="onConfirm" >
      <template #title>
        <div @click="showPicker = false; showDate1 = true">选择年月</div>
      </template>
    </van-calendar>
    <van-popup  v-if="config.hasTime" v-model="showPicker" position="bottom">
      <van-datetime-picker
              v-model="defaultDate"
              @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup>
    <van-popup v-model="showDate1" position="bottom" round>
      <van-datetime-picker
              v-model="defaultDate"
              type="year-month"
              @cancel="showDate1 = false"
              @confirm="onYMOk"
              :max-date="maxYM"
              :min-date="minYM"
      />
    </van-popup>
  </div>
</template>

<script>
import { CellGroup, Field, Popup, Calendar, DatetimePicker, Button } from 'vant'
export default {
    name: 'nxMobileDatetime',
    components: {
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Popup.name]: Popup,
        [Calendar.name]: Calendar,
        [DatetimePicker.name]: DatetimePicker,
        [Button.name]: Button
    },
    props: {
        mastertable: Object,
        tables: Array,
        dataSet: Object,
        config: Object,
        label: String,
        isEditSaved: Boolean,
        rowindex: Number,
        tablecode: String,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        changeValue: Function
    },
    data () {
      return {
          field: null,
          defaultDate: new Date(),
          showPicker: false,
          minYM: new Date(1900, 0, 1),
          maxYM: new Date(2099, 11, 31),
          showDate1: false,
          // 最大值和最小值都要保证是这个月, 默认值是今天，这样布局中才只会显示一个月
          minDate: new Date(this.getFirstDay(new Date().getFullYear() , (new Date().getMonth() + 1))),
          maxDate: new Date(this.getLastDay(new Date().getFullYear() , (new Date().getMonth() + 1)))
      }
    },
    mounted () {
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            if (table.TableCode === this.tablecode) {
                for (let j = 0; j < table.Fields.length; j++) {
                    if (table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
            }
            if (this.field !== null) {
                break
            }
        }
        console.log(this.field)
    },
    methods: {
        onYMOk (checkResult) {
            this.defaultDate = checkResult
            this.minDate = new Date(this.getFirstDay(checkResult.getFullYear(), (checkResult.getMonth() + 1)))
            this.maxDate = new Date(this.getLastDay(checkResult.getFullYear(), (checkResult.getMonth() + 1)))
            this.showDate1 = false
            this.showPicker = true
        },
        onConfirm (checkResult) {
            this.defaultDate = checkResult
            let date = checkResult.getFullYear().toString() + '-' + (checkResult.getMonth() + 1).toString() + '-' + checkResult.getDate().toString();
            if (this.config.hasTime) {
                date =  date +  ' ' + checkResult.getHours().toString() + ':' + checkResult.getMinutes().toString()
            }
            this.changeValue(this.tablecode, this.rowindex, this.config.FieldCode, date)
            this.showPicker = false
        },
        editFieldBefor () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.editFieldBeforeV2()
            if (!this.isEditSaved && this.field.Editable) {
                this.showPicker = true
            }
        },
        getFirstDay (y, m) {
            let d = '01'
            m = m < 10 ? '0' + m : m; //月份补 0
            return [y, m, d].join("-")
        },
        getLastDay(y, m){
            let d = new Date(y, m, 0).getDate(); //获取当月最后一日
            m = m < 10 ? '0' + m : m; //月份补 0
            d = d < 10 ? '0' + d : d; //日数补 0

            return [y,m,d].join("-")
        }
    }
}
</script>

<style scoped>

</style>
