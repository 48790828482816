<template>
  <div class="container">
    <!--<nx-side-bar :small="sideBarShow"/>-->
    <nx-header :small="sideBarShow"/>
    <div :class="['main-container', sideBarShow ? '' : 'small']">
      <transition name="fade-transform" mode="out-in">
        <router-view :key="$route.path" />
      </transition>
    </div>
    <nx-footer :small="sideBarShow" :showbadges="showbadges"/>
  </div>
</template>

<script>
import NxHeader from '@/layouts/components/NxHeader'
import NxFooter from '@/layouts/components/NxFooter'
import config from '@/config'
export default {
  name: 'LayoutDefault',
  components: {
    [NxHeader.name]: NxHeader,
    [NxFooter.name]: NxFooter
  },
  provide() {
      return {
          reload: this.reload
      }
  },
  data: () => ({
    sideBarShow: config.sideBarShow,
    showbadges: true
  }),
  created () {
    this.$bus.on('nav', v => {
      this.sideBarShow = v
    })
  },
  methods: {
     reload() {
        this.showbadges = !this.showbadges
     },
   },
}
</script>

<style scoped>
.main-container {
  /*height: calc(100vh - 48px);*/
  /*margin: 48px 0 0 130px;*/
  /*position: relative;*/
  transition: all 0.28s ease-in-out .01s;
  position: absolute;
  top: 30px;
  right: 0;
  bottom: 0;
  left: 130px;
  overflow: hidden;
}

.main-container.small{
  /*margin-left: 64px;*/
  left: 0px;
  transition: all 0.28s ease-in-out .01s;
}

</style>
