<template>
 <div  style="position: relative;">
   <!-- eslint-disable -->
   <van-tabs v-model="active">
     <van-tab v-for="(component, i) in config.componentList" :title="component.title" :key="i" :name="i">
       <div style="margin-left: 10px; margin-right: 10px; margin-top: 5px; background-color: #ffffff; border-radius: 8px; box-shadow: 0px 0px 5px #ccc">
         <component
           :is="item.component"
           v-for="(item, index) in component.pageComponents"
           :key="index"
           :data-set="dataSet"
           :label="item.label"
           :config="item.setStyle"
           :id="item.id"
           :style="{
                  float: 'left',
                  width: item.setStyle === undefined ? '100%' : item.setStyle.col === '1/2' ? '50%' :item.setStyle.col === '1/3' ? '33%' : item.setStyle.col === '1/4' ? '25%' : '100%'
                }"
           :is-edit-saved="isEditSaved"
           :is-query="IsQuery"
           :resource-home = 'resourceHome'
           :on-click-upload-image="onClickUploadImage"
           :pop-image-info="popImageInfo"
           :pop-affix-info="popAffixInfo"
           :preview-affix="previewAffix"
           :on-click-show-image="onClickShowImage"
           :remove-image="removeImageV2"
           :remove-affix="removeAffixV2"
           :change-value="changeValue"
           :rowindex = "0"
           :add-focuse-row="addFocuseRow"
           :edit-field-before="editFieldBefore"
           :edit-field-before-v2="editFieldBeforeV2"
           :edit-field-after="editFieldAfterV2"
           :on-click-upload-affix="onClickUploadAffix"
           :on-click-upload-video="onClickUploadVideo"
           :tablecode = "item.setStyle.TableCode"
           :field="item.setStyle.field"
           :tables="tables"
           :mastertable="mastertable"
           :link-report-v2 = "linkReportV2"
           :delete-focuse-row = "deleteFocuseRowV2"
           :do-action="doAction"
           :dic-all-checkbox-item="dicAllCheckboxItem"
           :show-focuse-table = "showFocuseTable"
           :tablerowindex="rowIndex"
           :add-focuse-next-row="addFocuseNextRow"
           :on-click-open-list="onClickOpenList"
           :sg-bar-code="sgBarCodeV2"
           :refresh="refresh"
         >
         </component>
       </div>
     </van-tab>
   </van-tabs>
   <!-- 删除组件 -->
   <slot name="deles" />
 </div>
</template>

<script>
import { Tabs, Tab } from 'vant'
export default {
  name: 'nxMobileTab',
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab

  },
  props: {
      mastertable: Object,
      tables: Array,
      value: String,
      config: Object,
      children: Array,
      field: Object,
      cssdiy: Object,
      label: String,
      isEditSaved: Boolean,
      onClickUploadImage: Function,
      popImageInfo: Function,
      onClickShowImage: Function,
      removeImage: Function,
      removeAffix: Function,
      resourceHome: String,
      dataSet: Object,
      changeValue: Function,
      addFocuseRow: Function,
      editFieldBefore: Function,
      editFieldBeforeV2: Function,
      popAffixInfo: Function,
      previewAffix: Function,
      onClickUploadAffix: Function,
      editFieldAfter: Function,
      linkReportV2: Function,
      onClickOpenList: Function,
      sgBarCode: Function,
      dicAllCheckboxItem: Object,
      showFocuseTable: Boolean,
      rowIndex: Number,
      addFocuseNextRow: Function,
      sgBarCodeV2: Function,
      refresh: Boolean,
      IsQuery: Boolean,
      removeImageV2: Function,
      removeAffixV2: Function,
      editFieldAfterV2: Function,
      onClickUploadVideo: Function
  },
  watch: {
    config: {
      handler () {
        this.active = Number(this.config.active)
        this.$forceUpdate()
      },
      deep: true
    }
  },
  data () {
    return {
      active: Number(this.config.active)
    }
  }
}
</script>

<style scoped>

</style>
