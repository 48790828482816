<template>
  <div style="position: relative;">
    <div style="margin-left: 15px; margin-right: 15px; margin-top: 15px;">
      {{config.label}}
      <div  style="display: inline;color: #1989fa;"  @click="addFocuseRow(config.TableCode)"  v-if="!isEditSaved && !IsQuery">
        <van-icon name="add" size="18" />
      </div>
    </div>
    <div style="margin-left: 10px; margin-right: 10px; margin-top: 5px; background-color: #ffffff; border-radius: 8px; box-shadow: 0px 0px 5px #ccc">
      <nx-table :fields="config.fieldScheam" :tablers="dataSet[config.TableCode]"  :show-operator="true" :operatetype="2" :menupos="'左'" :edit-row="editRow"
                :is-edit-saved="isEditSaved"
                :is-query="IsQuery"
                :resource-home = 'resourceHome'
                :on-click-upload-image="onClickUploadImage"
                :pop-image-info="popImageInfo"
                :pop-affix-info="popAffixInfo"
                :preview-affix="previewAffix"
                :on-click-show-image="onClickShowImage"
                :remove-image="removeImage"
                :change-value="changeValue"
                :add-focuse-row="addFocuseRow"
                :edit-field-before="editFieldBefore"
                :edit-field-after="editFieldAfter"
                :tablecode = "config.TableCode"
                :delete-row = "deleteFocuseRow"
                :on-click-upload-affix="onClickUploadAffix"></nx-table>
    </div>
    <van-overlay :show="showFocuseTableLocal" :lock-scroll="false">
      <div style="margin-left: 1%; margin-right:  1%; margin-top: 5px; background-color: #f3f0f7; border-radius: 8px; box-shadow: 0px 0px 5px #ccc; height: 98%;"  >
        <div style="height: 1%">&nbsp;</div>
        <div style=" height: 92%; overflow-x: hidden; overflow-y: auto;">
          <component
                  :is="item.component"
                  v-for="(item, index) in config.fieldJson"
                  :key="index"
                  :data-set="dataSet"
                  :label="item.label"
                  :config="item.setStyle"
                  :id="item.id"
                  :style="{
                  float: 'left',
                  width: item.setStyle === undefined ? '100%' : item.setStyle.col === '1/2' ? '50%' :item.setStyle.col === '1/3' ? '33%' : item.setStyle.col === '1/4' ? '25%' : '100%'
                }"
                  :is-edit-saved="isEditSaved"
                  :resource-home = 'resourceHome'
                  :on-click-upload-image="onClickUploadImage"
                  :pop-image-info="popImageInfo"
                  :pop-affix-info="popAffixInfo"
                  :preview-affix="previewAffix"
                  :on-click-show-image="onClickShowImage"
                  :remove-image="removeImage"
                  :remove-affix="removeAffix"
                  :change-value="changeValue"
                  :rowindex = "rowindex"
                  :add-focuse-row="addFocuseRow"
                  :edit-field-before="editFieldBefore"
                  :edit-field-before-v2="editFieldBeforeV2"
                  :edit-field-after="editFieldAfter"
                  :on-click-upload-affix="onClickUploadAffix"
                  :field="item.setStyle.field"
                  :tables="tables"
                  :mastertable="mastertable"
                  :tablecode = "item.setStyle.TableCode"
                  :on-click-open-list="onClickOpenList"
                  :sg-bar-code="sgBarCode"
                  :link-report-v2 = "linkReportV2"
          >
          </component>
        </div>
        <div style="margin-left: 1%; position: fixed; bottom: 2%; width: 98%">
          <van-row  v-show="showFocuseTableLocal && !isEditSaved">
            <van-col span="12"><van-button round type="info" style="width: 90%; border-radius: 10px; margin-left: 5%" @click="overlayClick" >关闭</van-button></van-col>
            <van-col span="12"><van-button round type="info" style="width: 90%; border-radius: 10px; margin-left: 5%" v-if="!isFocuseDataRowEdit"  @click="addFocuseNextRow" >连续新增</van-button></van-col>
            <van-col span="12"><van-button round type="info" style="width: 90%; border-radius: 10px; margin-left: 5%" v-if="isFocuseDataRowEdit"  @click="editFocuseNextRow">连续编辑</van-button></van-col>
          </van-row>
          <van-row  v-show="showFocuseTableLocal && isEditSaved">
            <van-col span="24">
              <van-button round type="info" style="width: 90%; border-radius: 10px; margin-left: 2.5%"  @click="overlayClick" >关闭</van-button>
            </van-col>
          </van-row>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import NxTable from '../componentsH5/NxTable/NxTable'
import { Icon, Button } from 'vant'
export default {
    name: 'nxMobileTable',
    components: {
        [NxTable.name]: NxTable,
        [Icon.name]: Icon,
        [Button.name]: Button
    },
    props: {
        mastertable: Object,
        tables: Array,
        IsQuery:{
            type: Boolean,
            default: false
        },
        value: String,
        config: Object,
        children: Array,
        field: Object,
        cssdiy: Object,
        label: String,
        dataSet: Object,
        isEditSaved: Boolean,
        addFocuseRow: Function,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        editFieldAfter: Function,
        onClickUploadImage: Function,
        popImageInfo: Function,
        onClickShowImage: Function,
        removeImage: Function,
        removeAffix: Function,
        resourceHome: String,
        changeValue: Function,
        popAffixInfo: Function,
        previewAffix: Function,
        onClickUploadAffix: Function,
        deleteFocuseRow: Function,
        showFocuseTable: Boolean,
        tablerowindex: Number,
        addFocuseNextRow: Function,
        linkReportV2: Function,
        onClickOpenList: Function,
        sgBarCode: Function
    },
    mounted () {
        console.log(this.config)
    },
    watch: {
        showFocuseTable () {
            // this.rowindex = this.tablerowindex
            // this.showFocuseTableLocal = this.showFocuseTable
            if (this.showFocuseTable) {
                this.editRow(this.tablerowindex, true, true)
            }
        },
        tablerowindex () {
            if (this.showFocuseTable && this.tablerowindex >= 0) {
                this.editRow(this.tablerowindex, true, true)
            }
        }
    },
    data () {
      return {
          showFocuseTableLocal: false,
          rowindex: 0,
          isFocuseDataRowEdit: true
      }
    },
    methods: {
        editRow(index, isadd, isshow) {
            this.rowindex = index
            this.showFocuseTableLocal = true
            if (!this.isEditSaved && (index + 1) === this.dataSet[this.config.TableCode].length) {
                this.isFocuseDataRowEdit = false
            }
            if (!this.isEditSaved && (index + 1) < this.dataSet[this.config.TableCode].length) {
                this.isFocuseDataRowEdit = true
            }
        },
        overlayClick() {
            this.showFocuseTableLocal = false
        },
        editFocuseNextRow () {
            this.rowindex = this.rowindex + 1
        }
    }
}
</script>

<style scoped>

</style>
