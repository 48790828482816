import Vue from 'vue'
import VueRouter from 'vue-router'
// import util from '../utils'
import LayoutDefault from '../layouts/LayoutDefault'
import LayoutCustom from '../layouts/LayoutCustom'
import appHelper from '../com/helper'
Vue.use(VueRouter)

// TODO: meta.cache 用于 keep-alive，暂时没有使用，不缓存所有组件
export const constantRoutes = [
    {
        path: '/',
        component: LayoutCustom,
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "l" */ '../views/H5Switch'),
                name: 'H5Switch',
                meta: {
                    title: '', icon: 'mdi-view-dashboard', cache: false, auth: false
                }
            }
        ]
    },
    {
        path: '/',
        component: LayoutDefault,
        hidden: true,
        children: [
            {
                path: '/message',
                component: () => import(/* webpackChunkName: "g" */ '../views/Message'),
                name: 'message',
                meta: {
                    title: '消息', icon: 'message', cache: false, auth: true
                },
                title: '消息',
                normal: require('@/assets/images/home/message.png'),
                active: require(`@/assets/images/home/messageActive.png`)
            },
            {
                path: '/gtasks',
                component: () => import(/* webpackChunkName: "g" */ '../views/Task'),
                name: 'gtasks',
                meta: {
                    title: '流程办理', icon: 'task', cache: false, auth: true
                },
                title: '待办',
                normal: require(`@/assets/images/home/task.png`),
                active: require(`@/assets/images/home/taskActive.png`)
            },
            {
                path: '/Home',
                component: () => import(/* webpackChunkName: "l" */ '../views/Home'),
                name: 'Home',
                meta: {
                    title: 'login.titleIn', icon: 'mdi-view-dashboard', cache: false, auth: true
                },
                title: '主页',
                normal: require(`@/assets/images/home/home1.png`),
                active: require(`@/assets/images/home/home1Active.png`)
            },
            {
                path: '/App',
                component: () => import(/* webpackChunkName: "l" */ '../views/App'),
                name: 'App',
                meta: {
                    title: 'login.titleIn', icon: 'mdi-view-dashboard', cache: false, auth: true
                },
                title: '应用',
                normal: require(`@/assets/images/home/apps.png`),
                active: require(`@/assets/images/home/appsActive.png`)
            },
            // {
            //     path: '/chartView',
            //     component: () => import(/* webpackChunkName: "g" */ '../views/ChartView'),
            //     name: 'chartView',
            //     meta: {
            //         title: '慧分析', icon: 'task', cache: false, auth: true
            //     },
            //     title: '慧分析',
            //     normal: require(`@/assets/images/home/chart.png`),
            //     active: require(`@/assets/images/home/chartActive.png`)
            // },
            {
                path: '/me',
                component: () => import(/* webpackChunkName: "l" */ '../views/Me'),
                name: 'me',
                meta: {
                    title: 'login.titleIn', icon: 'mdi-view-dashboard', cache: false, auth: false
                },
                title: '我',
                normal: require(`@/assets/images/home/me.png`),
                active: require(`@/assets/images/home/meActive.png`)
            }
        ]
    },
    {
        path: '/',
        component: LayoutCustom,
        hidden: true,
        children: [
            {
                path: '/login',
                component: () => import(/* webpackChunkName: "l" */ '../views/Login'),
                name: 'Login',
                meta: {
                    title: '登陆', icon: 'mdi-view-dashboard', cache: false, auth: false
                }
            }
        ]
    },
    {
        path: '/',
        component: LayoutCustom,
        hidden: true,
        children: [
            {
                path: '/warning',
                component: () => import(/* webpackChunkName: "l" */ '../views/Warning'),
                name: 'Warning',
                meta: {
                    title: '预警', icon: 'mdi-view-dashboard', cache: false, auth: false
                }
            }
        ]
    },
    {
        path: '/',
        component: LayoutCustom,
        hidden: true,
        children: [
            {
                path: '/wxbinding',
                component: () => import(/* webpackChunkName: "l" */ '../views/WXBinding'),
                name: 'wxbingding',
                meta: {
                    title: '绑定', icon: 'mdi-view-dashboard', cache: false, auth: false
                }
            }
        ]
    },
    {
        path: '/',
        component: LayoutCustom,
        hidden: true,
        children: [
            {
                path: '/register',
                component: () => import(/* webpackChunkName: "l" */ '../views/Register'),
                name: 'register',
                meta: {
                    title: '注册', icon: 'mdi-view-dashboard', cache: false, auth: false
                }
            }
        ]
    },
    {
        path: '/',
        component: LayoutCustom,
        hidden: true,
        children: [
            {
                path: '/reportlist',
                component: () => import(/* webpackChunkName: "l" */ '../views/ReportList'),
                name: 'ReportList',
                meta: {
                    title: '工作台列表', icon: 'mdi-view-dashboard', cache: false, auth: false
                }
            }
        ]
    },
]

const router = new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: constantRoutes
})

function setupWKWebViewJavascriptBridge (callback) {
    if (window.WKWebViewJavascriptBridge) { return callback(window.WKWebViewJavascriptBridge); }
    if (window.WKWVJBCallbacks) { return window.WKWVJBCallbacks.push(callback); }
    window.WKWVJBCallbacks = [callback];
    try{
        window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null)
    }catch (e) {

    }
}
router.beforeEach((to, from, next) => {
    setupWKWebViewJavascriptBridge(function(bridge) {
        const javascriptBridge = bridge
        window.iosH5EventHandle = function (params) {
            if (params.options === 'switchServer') {
                javascriptBridge.callHandler('switchServer')
            }
        }
    })
    if (to.name === 'H5Switch' && from.name === 'Login') {
        let SystemInfo = appHelper.getObject('SystemInfo')
        let userAgent = appHelper.browsType()
        if (userAgent == 'AndroidClient') {
            Android.switchServer()
        }
        if (userAgent == 'iOSClient' || userAgent == 'iOSBrows') {
            if (SystemInfo.SystemVersion == 'iOS8') {
                switchServer()
                return
            }
            if (window.WKWebViewJavascriptBridge){
                window.iosH5EventHandle({options: 'switchServer'})
            } else {
                window.webkit.messageHandlers.switchServer.postMessage({code: '0001', functionName: 'switchServer'});
            }
        }
    } else {
        next()
    }
    // window.alert(from)
  // if (to.meta.auth) {
  //   const token = util.getStorage('accessToken') || ''
  //   if (!token) {
  //     next({ name: 'Login', params: { redirect: to.fullPath } })
  //   } else {
  //     next()
  //   }
  // } else {
  //   next()
  // }
})

export default router
