<template>
  <div class="nav" :class="small ? '' : 'small'">
    <div style="font-family: Arial; font-size: small; font-weight: normal">{{department}}</div>
    <div style="font-family: Arial; font-size: small; font-weight: normal">{{weekDay}}</div>
  </div>
</template>
<script>
    import appHelper from '../../com/helper'
export default {
  name: 'NxHeader',
    data(){
      return {
          weekDay: '',
          department: ''
      }
    },
  components: {
  },
  created () {
      if (!appHelper.getObject('NXCELLS_ACCESS_TOKEN')) {
          this.$router.push('login')
      }
      const userinfo = appHelper.getObject('NXCELLS_USERINFO')
      this.department = userinfo.DeptName
      switch (new Date().getDay()) {
          case 0:
              this.weekDay = '星期日'
              break
          case 1:
              this.weekDay = '星期一'
              break
          case 2:
              this.weekDay = '星期二'
              break
          case 3:
              this.weekDay = '星期三'
              break
          case 4:
              this.weekDay = '星期四'
              break
          case 5:
              this.weekDay = '星期五'
              break
          case 6:
              this.weekDay = '星期六'
              break
      }
  },
  props: {
    small: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="less" scoped>
.nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 130px;
  height: 30px;
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  /*background-color: #F8F8F6FF;*/
  font-size: 1rem;
  transition: all 0.28s ease-in-out;
/*box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 0px rgb(0 0 0 / 5%);*/
&.small{
  left: 0px;
  transition: all 0.28s ease-in-out .01s;
}
}
.nav-left{
display: flex;
align-items: center;
}
.nav-right{
display: flex;
justify-content: flex-end;
align-items: center;
}
</style>
