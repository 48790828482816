<template>
  <div class="report-v2" style="position: relative;" v-if="field.Visable">
    <van-field :required="field.Nullable == true"
               :label-width="config.labelWidth"  :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >
      <template slot="label">
        <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + config.wordColor +'; font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px; height: ' +
            config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'">{{ config.name }}</div>
      </template>
      <template slot="input">
        <input :placeholder="'弹出列表'"
               @click="editFieldBefor" readonly  :disabled="isEditSaved || !field.Editable"
               v-model="dataSet[tablecode][rowindex][config.FieldCode]"
               :style="'font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px; background-color: #fff; height: ' +
          config.cellHeight + 'px; line-height: ' +  config.cellHeight
              + 'px;'" />
      </template>
      <template #button>
        <van-button v-if="field.Linkers != null  && field.Linkers.length > 0"  type="default" style="width: 12px;height: 12px;" :icon="require('@/assets/images/report/linker.png')" @click="linkReport()"></van-button>
      </template>
    </van-field>
  </div>
</template>

<script>
import { Field, Popup, Dialog, Button } from 'vant'
export default {
  name: 'nxMobileDatalist',
  components: {
      [Field.name]: Field,
      [Dialog.name]: Dialog,
      [Popup.name]: Popup,
      [Button.name]: Button

  },
    props: {
        mastertable: Object,
        tables: Array,
        dataSet: Object,
        config: Object,
        label: String,
        isEditSaved: Boolean,
        rowindex: Number,
        tablecode: String,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        editFieldAfter: Function,
        linkReportV2: Function,
        changeValue: Function,
        onClickOpenList: Function
    },
    data () {
        return {
            dataSpecAllowManual:false,
            dataSpecAllowMultSelect:false,
            field: null,
            editors: [],
            showLists: false,
            showList: false,
            searchDataListInput: '',
            loadingDataList: false,
            finishedDataList: false,
            imageDataList: [],
            dataSpecSummaryList: [],
            dataSpecFields: [],
            dataSpecInputData: {},
            localTable: {}
        }
    },
    mounted () {
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            if (table.TableCode === this.tablecode) {
                this.localTable = table
                for (let j = 0; j < table.Fields.length; j++) {
                    if (table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
            }
            if (this.field !== null) {
                break
            }
        }
    },
    methods: {
        linkReport () {
            this.linkReportV2(this.tablecode, this.config.FieldCode, this.rowindex)
        },
        async editFieldBefor () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.editFieldBeforeV2()
            // if (this.field.Editor !== undefined && this.field.Editor !== null) {
            //     const resultDataSpecID = await this.$api.getDataSpecID({
            //         DataSpecID: this.field.Editor.DataSpecID
            //     })
            //     if (resultDataSpecID.Code === 0) {
            //         this.field.Editor.DataSpecID = resultDataSpecID.Data
            //     }
            // } else {
            //     for (let i = 0; i < this.field.Editors.length; i++) {
            //         const resultDataSpecID = await this.$api.getDataSpecID({
            //             DataSpecID: this.field.Editors[i].DataSpecID
            //         })
            //         if (resultDataSpecID.Code === 0) {
            //             this.field.Editors[i].DataSpecID = resultDataSpecID.Data
            //         }
            //     }
            // }
            this.onClickOpenList(this.field, this.localTable, this.rowindex)
        }

    }
}
</script>

<style scoped>

</style>
