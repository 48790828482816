<template>
  <div :style="'position: relative; height:46px; text-align:' + config.align">
    <van-button type="small" :color="config.backColor" :disabled="isEditSaved" @click="buttonClick"  :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >
      <div :style="'color:' + config.wordColor +'; width:' + config.width + 'px;'">{{config.text}}</div>
    </van-button>
  </div>
</template>

<script>
    import { Field, Button } from 'vant'
    export default {
        name: 'nxMobileButton',
        components: {
            [Field.name]: Field,
            [Button.name]: Button
        },
        props: {
            value: String,
            label: String,
            config: Object,
            children: Array,
            field: Object,
            isEditSaved: Boolean,
            doAction: Function,
            dataRow: Object,
            isList: {
                type: Boolean,
                defalut: false
            }
        },
        methods: {
            buttonClick (event) {
                const listAction = []
                listAction.push(this.config.actionID)
                if (this.isList) {
                    this.doAction(listAction, this.dataRow['sys'].ReportID)
                } else {
                    this.doAction(listAction)
                }
                event.stopPropagation()
            }
        }
    }
</script>

<style scoped>

</style>
